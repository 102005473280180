<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="数据域名称" prop="dataFieldName">
        <el-input v-model="dataForm.dataFieldName" placeholder="数据域名称"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item size="mini" label="数据域选择">
        <el-tree
            :data="dataFieldList"
            :props="dataFieldListTreeProps"
            node-key="id"
            ref="dataFieldListTree"
            accordion
            :default-expand-all=treeExpand
            show-checkbox>
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      listData: [],
      dataFieldList: [],
      equipmentIndex: -1,
      treeExpand: false,
      dataFieldListTreeProps: {
        label: 'value',
        children: 'children'
      },
      dataForm: {
        id: 0,
        dataFieldName: '',
        remark: ''
      },
      dataRule: {
        dataFieldName: [
          { required: true, message: '数据域名称不能为空', trigger: 'blur' }
        ]
      },
      tempKey: -666666 // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
    }
  },
  methods: {
    init (id) {
      this.treeExpand = !id ? false : true
      this.dataForm.id = id || 0
      this.$http({
        url: this.$http.adornUrl('/universaltable/getTreeData/'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.listData = data.data
        this.treeDataSize=data.data.length
        this.dataFieldList = treeDataTranslate(data.data, 'id')
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.$refs.dataFieldListTree.setCheckedKeys([])
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/dataField/info/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.dataFieldName = data.dataField.dataFieldName
              this.dataForm.remark = data.dataField.remark
              let treeData = [];
              for (let i = 0; i < this.treeDataSize; i++) {
                for (let j=0;j<data.dataField.treeData.length; j++){
                  if(data.dataField.treeData[j].level_id===this.$refs.dataFieldListTree.getNode(i+1).data.levelId
                      && this.$refs.dataFieldListTree.getNode(i+1).data.rowId===data.dataField.treeData[j].row_id){
                    treeData.push(i+1);
                    break;
                  }
                }
              }
              // for (let i = 0; i < data.dataField.treeData.length; i++) {
              //   for (let j = 0; j < this.listData.length; j++) {
              //     console.log("treedata",i,data.dataField.treeData[i])
              //     console.log("listData",j,data.listData[j+1])
              //     console.log(this.listData.slice(this.equipmentIndex - 1))
              //     if(data.dataField.treeData[i].level_id == this.listData[j+1].levelId){
              //       treeData.push(this.listData[j+1].id);
              //       break;
              //     }
              //   }
              // }
              console.log(treeData)
              this.$refs.dataFieldListTree.setCheckedKeys(treeData)
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let treeData=[];
          for (let i = 0; i < this.$refs.dataFieldListTree.getCheckedNodes().length; i++) {
            let o={};
            o.projectId=(this.$refs.dataFieldListTree.getCheckedNodes()[i].projectId)
            o.levelId=(this.$refs.dataFieldListTree.getCheckedNodes()[i].levelId)
            o.rowId=(this.$refs.dataFieldListTree.getCheckedNodes()[i].rowId)
            treeData.push(o)
          }
          console.log(treeData)
          this.$http({
            url: this.$http.adornUrl(`/dataField/${!this.dataForm.id ? 'save' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              'dataFieldId': this.dataForm.id || undefined,
              'dataFieldName': this.dataForm.dataFieldName,
              'remark': this.dataForm.remark,
              'treeData': treeData,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>
